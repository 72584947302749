 const loginSuccess = (userID) => {
    return {
      type: 'LOGIN_SUCCESS',
      payload: {
        isLoggedIn: true,
        userID: userID,
      },
    };
  };
  
   const logout = () => {
    return {
      type: 'LOGOUT',
    };
  };
  export default{loginSuccess,logout};
  