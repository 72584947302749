import { useState } from "react"
import axios from "axios"
import { Navigate, useNavigate } from "react-router-dom"
import { message } from "antd";

import AdminNavBar from "./adminNavBar"
const NavBar = () => {
    const token = localStorage.getItem('token');
    const config = {
        headers: {
            Authorization: token
        }
    };
    const navigate = useNavigate();
    function navigateTo (component){
        navigate(component);
    }
    async function SignOut(event) {
        event.preventDefault();
        try {
            const data = { token };
            axios.post(`${process.env.REACT_APP_API_URL}/Api/SignOut`, data,config)
            .then(res =>{
                if(res.status === 200){
                    message.success(res.data.message);
                    navigate("/");
                }
            }).catch( err => console.log(err)
                //message.error("Cannot Sign-out")
            )
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-light" style={{padding:'15px',background:'#cdddd3'}}>
                <a className="navbar-brand" href="/roomsAvailable">Raihan Rooms</a>

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                        {/* <li className="nav-item active">
                            <a className="nav-link" href="/roomsAvailable">Bookings</a>
                        </li> */}
                        
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Rooms
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                {/* <a className="dropdown-item" onClick={() => navigateTo("/roomsAvailable")}>Request booking</a> */}
                                <a className="dropdown-item"  onClick={() => navigateTo("/myBookings")}>My bookings</a>
                                <div className="dropdown-divider"></div>
                                <a className="dropdown-item" href="#">Raihan rooms</a>
                            </div>
                        </li>
                        
                        <AdminNavBar/>  
                        <li className="nav-item">
                            <a className="nav-link" href="/userProfile" >Account</a>
                        </li>                      
                        <li className="nav-item">
                            <a className="nav-link" href="/" title="Sign-out" onClick={SignOut}><i className="bi bi-box-arrow-right" style={{fontSize:'larger'}}></i></a>
                        </li>
                    </ul>

                </div>

            </nav>
        </div>
    )
}
export default NavBar;