import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import React from "react";
import axios from "axios";
import { Avatar, Card, Button, Modal, Badge, Popconfirm, message, DatePicker } from 'antd';
import moment from "moment";
import NavBar from "./navBar";
import { calculateNumberOfDays } from "../Reusable/getTotalDays";
import { decryptData } from "../Encryption/encrypt";

const ChangeDate = () => {
    const [selectPkg, setSelectPkg] = useState([]);
    const [dateRange, setDateRange] = useState([]);
    const [arrival, setArrival] = useState('');
    const [departure, setDeparture] = useState('');
    const [totalDays, setDays] = useState(0);
    const [available, setAvailable] = useState(false);
    const [AmtEstimate, setAmt] = useState(0);
    const [pkg, setPackage] = useState([]);

    const [selectedBooking, setBooking] = useState([]);

    const formate = 'DD-MM-YYYY HH:mm';
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const roomParam = params.get('room');

    const token = localStorage.getItem('token');
    const config = {
        headers: {
            Authorization: token
        }
    };
    useEffect(() => {
        if (roomParam) { //JSON.parse(decodeURIComponent(roomParam))
            const decodedRoom = decryptData(roomParam);
            setBooking(decodedRoom);

            // if (selectedBooking.RoomID != null) {
            //     getPackages();
            //     console.log("SelectedPkg", selectPkg);
            // }
        }
        if (arrival && departure) {
            const calculatedDays = calculateNumberOfDays(arrival, departure, formate);
            setDays(calculatedDays);
            getStatus();
        }

    }, [roomParam, arrival, departure, formate]);
    const handleRangeChange = (dates) => {
        if (!dates || dates.length === 0) {
            setDateRange([]);
            setArrival('');
            setDeparture('');
            setDays(0);

        } else {
            setDateRange(dates);
            setArrival(dates[0]?.format('DD-MM-YYYY HH:mm'));
            setDeparture(dates[1]?.format('DD-MM-YYYY HH:mm'));
        }
    };
    // const getStatus = async () => 
    async function getStatus() {
        try {

            const bookingID = selectedBooking.BookigID;
            const roomID = selectedBooking.RoomID;
            const Arrival = moment.tz(arrival, 'DD-MM-YYYY HH:mm', 'Asia/Kolkata').toDate();
            const Departure = moment.tz(departure, 'DD-MM-YYYY HH:mm', 'Asia/Kolkata').toDate();
            const data = { Arrival, Departure, bookingID, roomID };

            await axios.post(`${process.env.REACT_APP_API_URL}/Api/getRoomStatus`, data, config)
                .then(res => {
                    if (res.status === 200) {
                        setAvailable(true);
                        message.success("Room  available");
                    } if (res.status === 210) {
                        message.error("Room not available in the selected date");
                        setAvailable(false);
                    }
                }).catch(err => console.log(err));

        } catch (error) {

        }
    }

    // async function getPackages() {
    //     const roomID = selectedBooking.RoomID
    //     const packageID = selectedBooking.PackageID;
    //     const data = { roomID };
    //     setAmt(0);
    //     try {
    //         const response = await axios.post('/Api/getPackageList', data, config);

    //         if (response.status === 200) {
    //             setPackage(response.data);
    //             const packet = response.data.find((item) => item.PackageID === packageID);
    //             setSelectPkg(packet);
    //             console.log(selectPkg)
    //         }

    //     } catch (error) {
    //         console.error('Error fetching packages:', error);
    //     }
    // };
    async function UpdDate(){
           try{
            const bookingID = selectedBooking.BookigID;           
            const Arrival = moment.tz(arrival, 'DD-MM-YYYY HH:mm', 'Asia/Kolkata').toDate();
            const Departure = moment.tz(departure, 'DD-MM-YYYY HH:mm', 'Asia/Kolkata').toDate();
            const data = {bookingID,Arrival,Departure,totalDays,AmtEstimate};
            await axios.post(`${process.env.REACT_APP_API_URL}/Api/UpdatDate`,data,config)
            .then(res =>{
                if(res.status === 200){
                    message.success("Itinerary updated");
                }else{
                    message.success("Update failed !");
                }
            }).catch(err =>console.log(err))
           } catch(error){
            console.log(error);
           }
    }
    const getEstimate = async () => {
        const roomid = selectedBooking.RoomID;
        const packageID = selectedBooking.PackageID;
        const Occupancy = selectedBooking.NoOfGuests;
        const ratequoted = selectedBooking.RateQuoted;
        try {
            if(available){
                const data = { roomid, totalDays, packageID, Occupancy , ratequoted}
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/Api/getAmtEstimateForChangeDate`, data, config);
            if (res.status === 200) {
                setAmt(res.data.Amt);
            } else {
                setAmt(0);
            }
            }else{
                message.error("Room is not available");
            }
        } catch (error) {
            message.error('Failed to apply package');
            console.log(error)
        }

    };
    return (
        <div>
        <NavBar></NavBar>
        <div className="d-flex justify-content-center mt-3">
            <div className="col-lg-8">
                <div className="card">
                    <div className="card-body">
                        <div className="row justify-content-center">
                            <div className="col-lg-9">
                                <div className="card" style={{padding:'5px'}}>
                                    <div className="row justify-content-center">
                                        <div className="col-lg-10 mt-3">
                                            <Card className="shadow shadow-md"
                                                cover={
                                                    <div className="p-2">
                                                        <span style={{textAlign:'center'}}>
                                                            <h5>Original Itinerary</h5>
                                                        </span>
                                                        <p>Name: {selectedBooking.CustName} | {selectedBooking.Mobile}</p>
                                                        <p>Email: {selectedBooking.EmailID}</p>
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4">
                                                            <p>ChkIn : {selectedBooking.ChkInDate} </p> 
                                                            </div>
                                                            <div className="col-lg-1 col-md-1">
                                                            <i className="bi bi-arrow-right"></i>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4" style={{whiteSpace:'nowrap'}}>
                                                            <p>   ChkOut : {selectedBooking.ChkOutDate}</p>
                                                            </div>                                                                                                                        
                                                        </div>
                                                        <p>Total stay : For {selectedBooking.NoOfDays} days</p>
                                                        <p>Price : INR {selectedBooking.NetAmt} @ {selectedBooking.RateQuoted} per day</p>                                                            
                                                    </div>
                                                }
                                            >

                                            </Card>
                                        </div>
                                    </div>

                                    <div className="row text-center mt-3">
                                        <div className="col-lg-6" >
                                            <span>
                                                <h5><p>Total days : {totalDays}</p></h5>

                                            </span>
                                           
                                        </div>
                                        <div className="col-lg-6">
                                          <h5>  <p>Status: <span style={{ color: available ? "green" : "#eb0000eb" }}>{available ? "Available " : " Not Available "}</span></p></h5>
                                        </div>
                                        
                                    </div>
                                    {/* @ {selectedBooking.RateQuoted} per day */}
                                    <div className="card-body justify-content-center d-flex">

                                        <div className="row">

                                            <div className="col-lg-12">

                                                <DatePicker.RangePicker onChange={handleRangeChange}
                                                    format="DD-MM-YYYY"
                                                    showTime={{ format: 'HH:mm', minuteStep: 5 }}
                                                />


                                            </div>

                                        </div>

                                    </div>
                                    <div className="modal-footer">
                                        <Popconfirm
                                        title="Get estimate and Update"
                                        onConfirm={UpdDate}
                                        okText="Update"
                                        cancelText="cancel"
                                        description={
                                            <div>
                                                 <h5>Estimate: {AmtEstimate}</h5>
                                            </div>
                                        }
                                        >
                                        <button className="btn btn-primary" onClick={getEstimate}>Get estimate</button>
                                        </Popconfirm>
                                    
                                    {/* <button className="btn btn-success" onClick={UpdDate}>Update</button> */}
                                    </div>

                                    <div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}
// onClick={()=>getEstimate()} <button className="btn btn-primary" onClick={() => { getPackages(); getEstimate(); }}>Update</button>
export default ChangeDate;