import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Avatar, Card, Button, Modal, Badge, Popconfirm, message } from 'antd';
import moment from "moment";
import NavBar from "./navBar";
import { useNavigate } from "react-router-dom";
import { encryptData } from "../Encryption/encrypt";
import html2canvas from 'html2canvas';
import qrcode from '../assests/img/QRcode.jpeg'
import istefada from '../assests/img/istefada.jpeg'
const ApproveBooking = () => {
    const token = localStorage.getItem('token');
    const config = {
        headers: {
            Authorization: token
        }
    };
    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedUser, setData] = useState([]);
    const [discount, setDiscount] = useState(0);
    const [pkg, setPackage] = useState([]);
    const [selectPkg, setSelectPkg] = useState([]);
    const [Occupancy, setOccupancy] = useState(0);
    const [AmtEstimate, setAmt] = useState(0);
    const [open, setOpen] = useState(false);
    const [snapshot, setSnapshot] = useState(null);
    const [payment, setPayment] = useState(0);
    const [isIstefada,setIstefada] = useState(false);

    const tableRef = useRef(null);
    const captureAndSaveSnapshot = () => {
        if(selectPkg.PackageDesc.toLowerCase().includes("miqaat"))
        {
            setIstefada(true);
        }else{
            setIstefada(false)
        }
        const table = tableRef.current;
        table.classList.remove('d-none');
        html2canvas(table).then((canvas) => {
            const tableSnapshot = canvas.toDataURL('image/png');
            setSnapshot(tableSnapshot);
            table.classList.add('d-none');
        }).catch((error) => {
            console.error('Error capturing snapshot:', error);
        });
    };
    function sendMessage(mobile, chkIn, chkOut, noOfdays, noOfguests, Roomno, est, dis, netamt, name, status, paid,roomid,packageid) {
        setSnapshot(null);
        setData([]);
        const booking = {
            mobile,
            chkIn,
            chkOut,
            noOfdays,
            noOfguests,
            Roomno,
            est,
            dis,
            netamt,
            name,
            status,
            paid
        };
        setData(booking);
        setOpen(true);
        getPackages(roomid,packageid,noOfguests)
        //const whatsappURL = `https://api.whatsapp.com/send?phone=${mobile}&text=${encodeURIComponent(`Raihan Rooms\n Your booking has been confirmed \n Room No. : ${Roomno} Check-In Date : ${chkIn}\n Check-Out Date :${chkOut} \n Total Days : ${noOfdays} \n Total Guests : ${noOfguests}`)}`
        // window.open(whatsappURL, '_blank');
        //window.location.href = whatsappURL;
    };
    function sendSnap() {
        if (selectedUser.status === "ACCEPTED") {
            captureAndSaveSnapshot();
        }
    }
    function sendWhatsApp() {
        const whatsappURL = `https://api.whatsapp.com/send?phone=${selectedUser.mobile}&text=`
        window.open(whatsappURL, '_blank');
    }
    function closeModal() {
        setOpen(false);
    }
    useEffect(() => {
        getList();

    }, []);
    const confirm = (ID) => {
        axios.post(`${process.env.REACT_APP_API_URL}/Api/ApproveBooking`, { ID: ID }, config)
            .then(res => {
                if (res.status === 200) {
                    message.success('Booking approved');
                    getList();
                } else {
                    message.error('Booking was not approved');
                }
            }).catch(err => console.log(err));
    };
    const cancel = (e) => {
        // console.log(e);
        message.error('Cancelled');
    };
    const showModal = async (bookingID) => {
        try {
            const room = list.find((booking) => booking.BookigID === bookingID);
            // encodeURIComponent(JSON.stringify(room))
            const roomEncoded = encryptData(room);
            const url = `/changeDate?room=${roomEncoded}`;
            navigate(url);
        } catch (error) {

        }
    }
    const Reject = (ID) => {
        axios.post(`${process.env.REACT_APP_API_URL}/Api/RejectBooking`, { ID: ID }, config)
            .then(res => {
                if (res.status === 200) {
                    message.success('Booking cancelled');
                    getList();
                } else {
                    message.error('Booking was not cancelled');
                }
            }).catch(err => console.log(err));
    };

    const Discount = async (ID) => {
        const data = { ID, discount };
        axios.post(`${process.env.REACT_APP_API_URL}/Api/Discount`, data, config)
            .then(res => {
                if (res.status === 200) {
                    message.success('Discount was successfully given');
                    setDiscount(0);
                    getList();
                } else {
                    setDiscount(0);
                    message.error('Discount was not given');
                }
            }).catch(err => console.log(err));

    }
    const updPayment = async (bookingID, totalPaid, remaining, estimate) => {
        let total;
        if (Number(remaining) === Number(payment)) {
            total = payment;
        } else {
            total = Number(payment) + Number(totalPaid);
        }
        const data = { total, bookingID }
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/Api/updPayment`, data, config);
        if (response.status === 200) {
            message.success("Payment added");
            setPayment(0);
            getList();
        } else {
            message.error("Payment was not added")
        }
    }
    const handlePackageSelect = async (packageID, totalDays, guests, roomid) => {

        const selectedPakage = pkg.find((PKG) => PKG.PackageID === packageID)
        // const selectedBooking = list.find((bkg) => bkg.BookigID === bookingID);
        // setRoom(selectedBooking);
        setSelectPkg(selectedPakage);
        // console.log(selectedPakage);
        try {
            // const roomid = selectedRoom.RoomID;
            // const totalDays = selectedRoom.NoOfDays
            // const Occupancy = selectedRoom.NoOfGuests
            const data = { roomid, totalDays, packageID, Occupancy }
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/Api/getAmtEstimate`, data, config);
            if (res.status === 200) {
                setAmt(res.data.Amt);
            } else {
                setAmt(0);
            }
        } catch (error) {
            message.error('Failed to apply package');
        }

    };
    const getPackages = async (roomID, packageID, guests) => {
        setOccupancy(guests);
        const data = { roomID };
        setAmt(0);
        setSelectPkg([]);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/Api/getPackageList`, data, config);

            if (response.status === 200) {
                setPackage(response.data);
                const selectedPackage = response.data.find((item) => item.PackageID === packageID);
                setSelectPkg(selectedPackage);
            }

        } catch (error) {
            console.error('Error fetching packages:', error);
        }
    };
    const updPackage = async (pkgID, rateQuote, roomid, bookingID) => {
        try {
            const data = { pkgID, AmtEstimate, rateQuote, Occupancy, bookingID };
            axios.post(`${process.env.REACT_APP_API_URL}/Api/UpdatePkg`, data, config)
                .then(res => {
                    if (res.status === 200) {
                        message.success("Package updated");
                        getList();
                        getPackages(roomid, pkgID, Occupancy);
                    } if (res.status === 400) {
                        message.error("Package was not updated");
                    }
                }).catch(err => console.log(err));

        } catch (error) {
            //console.error(error);
        }
    };
    const getList = async () => {
        try {

            setLoading(true);
            axios.get(`${process.env.REACT_APP_API_URL}/Api/getBookingList`, config)
                .then(res => {
                    if (res.status === 200) {
                        const sortedData = res.data.slice();
                        sortedData.sort((a, b) => b.AmtEstimate - a.AmtEstimate);

                        // setList(res.data);
                        // list.sort((a, b) => b.AmtEstimate - a.AmtEstimate);
                        setLoading(false);
                        const formattedList = sortedData.map(item => {
                            return {
                                ...item,
                                BookingDate: moment(item.BookingDate).format('DD-MM-YYYY HH:mm'),
                                ChkInDate: moment(item.ChkInDate).format('DD-MM-YYYY HH:mm'),
                                ChkOutDate: moment(item.ChkOutDate).format('DD-MM-YYYY HH:mm'),
                                // Add other date fields here if necessary
                            };
                        });
                        setList(formattedList);
                        // console.log(res.data);
                    } else {
                        setLoading(false);
                    }
                }).catch(err => console.log(err));

        } catch (error) {
            // console.error(error);
        }
    }
    const [searchQuery, setSearchQuery] = useState('');
    const [CheckIn, setChkIN] = useState('');

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredList = list.filter(booking => {
        return (
            booking.CustName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            booking.EmailID.toLowerCase().includes(searchQuery.toLowerCase()) ||
            booking.Mobile.includes(searchQuery) ||
            booking.ChkInDate.includes(searchQuery) ||
            booking.ChkOutDate.includes(searchQuery)
        );
    });
    const handleArrivingTodayFilterClick = (state) => {
        const today = moment(new Date()).format('DD-MM-YYYY')
        if (state == 'arrival') {
            setChkIN('');
            axios.get(`${process.env.REACT_APP_API_URL}/Api/Arrivals`, config)
                .then(res => {
                    if (res.status == 200) {
                        const rawData = res.data;
                        const formattedList = rawData.map(item => {
                            return {
                                ...item,
                                ChkInDate: moment(item.ChkInDate).format('DD-MM-YYYY HH:mm'),
                                ChkOutDate: moment(item.ChkOutDate).format('DD-MM-YYYY HH:mm')
                            }
                        });
                        setChkIN(formattedList);
                        // console.log(res.data)
                    }
                }).catch(err => message.error("Error Occured !!"))

        }
        if (state == "Dep") {
            //  console.time("Timer")
            setChkIN('');
            axios.get(`${process.env.REACT_APP_API_URL}/Api/Departures`, config)
                .then(res => {
                    if (res.status == 200) {
                        const rawData = res.data;
                        const formattedList = rawData.map(item => {
                            return {
                                ...item,
                                ChkInDate: moment(item.ChkInDate).format('DD-MM-YYYY HH:mm'),
                                ChkOutDate: moment(item.ChkOutDate).format('DD-MM-YYYY HH:mm')
                            }
                        });
                        setChkIN(formattedList);
                        //  console.log(res.data)
                    }
                }).catch(err => message.error("Error Occured !!"))
            //  console.timeLog("Timer");
        }
        if (state == 'full') {
            setChkIN('');
        }
    }
    //    className={booking.IsBooked ? "badge bg-danger" : " badge bg-success"}    <Badge status="processing" text="" color={booking.IsBooked ? (booking.WhoBookedCustID == booking.CustID ? "red" : "orange") : "green"}/>
    return (
        <div>
            <NavBar />
            <div className="mt-2" style={{ justifyContent: 'center', display: 'flex' }}>
                <div className="col-lg-10" style={{ padding: '16px' }}>
                    <h3>Room Bookings</h3>
                    <div className="card shadow shadow-sm">
                        <div className="card-body">
                            <div className="row justify-content-center">
                                <div className="col-lg-7">
                                    <div className="form-floating">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Search..."
                                            value={searchQuery}
                                            onChange={handleSearch}
                                        />
                                        <label>Search...</label>
                                    </div>
                                </div>
                            </div>
                            <ul className="nav nav-tabs mb-3" id="ex1" role="tablist" style={{ whiteSpace: 'nowrap', flexWrap: 'nowrap' }}>
                                <li className="nav-item" role="presentation" onClick={() => handleArrivingTodayFilterClick("full")}>
                                    <a
                                        className="nav-link active"
                                        id="ex1-tab-1"
                                        data-bs-toggle="tab"
                                        href="#ex1-tabs-1"
                                        role="tab"
                                        aria-controls="ex1-tabs-1"
                                        aria-selected="true"
                                    >Full List</a
                                    >
                                </li>
                                <li className="nav-item" role="presentation" onClick={() => handleArrivingTodayFilterClick("arrival")}>
                                    <a
                                        className="nav-link"
                                        id="ex1-tab-2"
                                        data-bs-toggle="tab"
                                        href="#ex1-tabs-2"
                                        role="tab"
                                        aria-controls="ex1-tabs-2"
                                        aria-selected="false"
                                    >Arriving Today</a
                                    >
                                </li>
                                <li className="nav-item" role="presentation" onClick={() => handleArrivingTodayFilterClick("Dep")}>
                                    <a
                                        className="nav-link"
                                        id="ex1-tab-3"
                                        data-bs-toggle="tab"
                                        href="#ex1-tabs-3"
                                        role="tab"
                                        aria-controls="ex1-tabs-3"
                                        aria-selected="false"
                                    >Departing Today</a
                                    >
                                </li>
                            </ul>
                            <div className="tab-content" id="ex1-content">
                                <div
                                    className="tab-pane fade show active"
                                    id="ex1-tabs-1"
                                    role="tabpanel"
                                    aria-labelledby="ex1-tab-1"
                                >
                                    {
                                        list.length > 0 ?
                                            (
                                                <div className="row mt-3" >
                                                    {
                                                        filteredList.map(booking => (
                                                            <div className="col-lg-4 col-md-6 mb-2" key={booking.BookigID}>

                                                                <Badge.Ribbon text={booking.IsBooked ?
                                                                    (booking.WhoBookedCustID == booking.CustID ? "Booked" : "UnAvailable") : "Available"}
                                                                    color={booking.IsBooked ? (booking.WhoBookedCustID == booking.CustID ? "red" : "orange") : "green"}>
                                                                    <Card loading={loading} className="shadow shadow-md"
                                                                        cover={
                                                                            <div className="p-2">
                                                                                <span>
                                                                                    <p><span style={{ fontWeight: '550' }}> ChkIn: </span>{booking.ChkInDate}</p>
                                                                                    <p><span style={{ fontWeight: '550' }}>ChkOut: </span>{booking.ChkOutDate} <a onClick={() => showModal(booking.BookigID)}>(change)</a></p>
                                                                                </span>
                                                                                <p>For : <span>{booking.NoOfDays} days</span>  @ <span style={{ textDecoration: booking.RateQuoted !== booking.RoomStdRate ? 'line-through' : 'none' }}>{booking.RoomStdRate}</span> <span style={{ display: booking.RateQuoted === booking.RoomStdRate ? 'none' : '' }}>{booking.RateQuoted} </span> per day</p>
                                                                                <p style={{ fontFamily: 'poppins', fontSize: 'small' }} >Room Status :  <span style={{ color: booking.IsBooked ? '#ff0000d9' : 'green' }}>{booking.IsBooked ? `Booked by ${booking.AlreadyBookedBy} | ${booking.BookedByMobileNo}` : "Available"} </span></p>
                                                                                <p>
                                                                                    <Popconfirm
                                                                                        title="Packages"
                                                                                        onConfirm={() => updPackage(selectPkg.PackageID, selectPkg.PackageRoomRate, booking.RoomID, booking.BookigID)}
                                                                                        onCancel={cancel}
                                                                                        okText="Ok"
                                                                                        cancelText="cancel"
                                                                                        description={
                                                                                            <div>
                                                                                                <div className="col-lg-6" style={{ display: 'flex', alignItems: 'center' }}>
                                                                                                    <input type="number" className="form-control" placeholder="No of Guests" value={Occupancy} onChange={e => setOccupancy(e.target.value)} />
                                                                                                </div>
                                                                                                <div className="dropdown mt-3">
                                                                                                    <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                                        {selectPkg?.PackageDesc || 'Select Package'}

                                                                                                    </button>

                                                                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">

                                                                                                        {pkg.map((packageItem) => (
                                                                                                            <a
                                                                                                                key={packageItem.PackageID}
                                                                                                                className={`dropdown-item ${selectPkg === packageItem.PackageID ? 'active' : ''}`}
                                                                                                                onClick={() => handlePackageSelect(packageItem.PackageID, booking.NoOfDays, booking.NoOfGuests, booking.RoomID)}
                                                                                                                href="#"
                                                                                                            >
                                                                                                                {packageItem.PackageDesc}
                                                                                                            </a>
                                                                                                        ))}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <p>Amount estimate :{AmtEstimate} for {booking.NoOfDays} days @ {selectPkg.PackageRoomRate} per day</p>
                                                                                            </div>
                                                                                        }
                                                                                    ><span>
                                                                                            <a style={{ color: 'rgba(255, 0, 0, 0.85)' }} onClick={() => getPackages(booking.RoomID, booking.PackageID, booking.NoOfGuests)}>See package</a> <span>Total Guests : {booking.NoOfGuests}</span>
                                                                                        </span>
                                                                                    </Popconfirm>
                                                                                </p>
                                                                                <p>Estimate : <span>{booking.AmtEstimate}</span>
                                                                                    <Popconfirm
                                                                                        title="Add payment"
                                                                                        okText="add"
                                                                                        onConfirm={() => updPayment(booking.BookigID, booking.Paid, booking.NetAmt, booking.AmtEstimate)}
                                                                                        cancelText="close"
                                                                                        description={
                                                                                            <div>
                                                                                                <div className="form-floating">
                                                                                                    <input type="number" className="form-control" placeholder="Payment amount" onChange={e => setPayment(e.target.value)} />
                                                                                                    <label>Payment amount</label>                                                                                                </div>
                                                                                            </div>
                                                                                        }
                                                                                    >
                                                                                        <small className="ml-3" style={{ color: 'rgba(255, 0, 0, 0.85)', cursor: 'pointer', marginLeft: '10px' }}> payments</small>
                                                                                    </Popconfirm>      <span> | Paid : {booking.Paid}</span>
                                                                                </p>
                                                                                <p> <span>{booking.Discount > 0 ? `Discount : ${booking.Discount}` : " "}</span> | Net Amount : <span>{booking.NetAmt} </span>
                                                                                    <a style={{ color: '#05ad05' }} onClick={
                                                                                        () => sendMessage(booking.Mobile, booking.ChkInDate, booking.ChkOutDate, booking.NoOfDays, booking.NoOfGuests, booking.RoomNo, booking.AmtEstimate, 
                                                                                            booking.Discount, booking.NetAmt, booking.CustName, booking.BookingStatus, booking.Paid,booking.RoomID,booking.PackageID
                                                                                        )}
                                                                                    >
                                                                                        Send message
                                                                                    </a>
                                                                                </p>

                                                                            </div>
                                                                        }
                                                                        actions={[
                                                                            <div className="row">
                                                                                <div className="col-lg-4 col-md-4">
                                                                                    <Popconfirm
                                                                                        title="Reject this booking !"
                                                                                        onConfirm={() => Reject(booking.BookigID)}
                                                                                        onCancel={cancel}
                                                                                        okText="Yes"
                                                                                        cancelText="No"
                                                                                    >
                                                                                        <button className="btn btn-outline-danger col-lg-10">REJECT</button>
                                                                                    </Popconfirm>
                                                                                </div>
                                                                                <div className="col-lg-4 col-md-4">
                                                                                    <Popconfirm
                                                                                        title="Approve this booking !"
                                                                                        onConfirm={() => confirm(booking.BookigID)}
                                                                                        onCancel={cancel}
                                                                                        okText="Yes"
                                                                                        cancelText="No"

                                                                                    >
                                                                                        <button className="btn btn-outline-success col-lg-10">ACCEPT</button>
                                                                                    </Popconfirm>
                                                                                </div>
                                                                                <div className="col-lg-4 col-md-4">
                                                                                    <Popconfirm
                                                                                        title="Discount"
                                                                                        onConfirm={() => Discount(booking.BookigID)}
                                                                                        onCancel={cancel}
                                                                                        okText="Ok"
                                                                                        cancelText="No"
                                                                                        description={
                                                                                            <div>
                                                                                                <input className="form-control" placeholder="Discount amount" type="number" id="discountAmt" text={discount} onChange={e => setDiscount(e.target.value)}></input>
                                                                                            </div>
                                                                                        }
                                                                                    >
                                                                                        <button className="btn btn-light col-lg-10">DISCOUNT</button>
                                                                                    </Popconfirm>
                                                                                </div>
                                                                            </div>

                                                                        ]}
                                                                    >
                                                                        {/* <div className="row">
                                                        <div className="col-lg-12">
                                                            HELLO
                                                        </div>
                                                    </div> */}
                                                                        <Card.Meta
                                                                            avatar={<Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel" />}
                                                                            title={<span>Request for<span> Room No {booking.RoomNo}</span> <br></br></span>}
                                                                            description={<div>
                                                                                <span>Requested By </span><br></br>
                                                                                <span style={{ fontFamily: 'poppins' }}>Name : <span style={{ fontWeight: '500' }}>{booking.CustName}</span></span><br></br>
                                                                                <span style={{ fontFamily: 'poppins' }}>Email :</span><span style={{ fontWeight: '500' }}>{booking.EmailID}</span><br></br>
                                                                                <span style={{ fontFamily: 'poppins' }}>Ph :</span><span style={{ fontWeight: '500' }}>{booking.Mobile}</span> <span>Status : <span style={{ color: booking.BookingStatus === 'REJECTED' ? 'red' : booking.BookingStatus === "REQUESTED" ? 'orange' : 'green', fontWeight: '500' }}>{booking.BookingStatus}</span></span> <br></br>
                                                                            </div>
                                                                            }

                                                                        >

                                                                        </Card.Meta>
                                                                    </Card>
                                                                </Badge.Ribbon>
                                                            </div>
                                                        ))
                                                    }
                                                    <Modal
                                                        title="Room Reciept"
                                                        open={open}
                                                        onCancel={closeModal}
                                                    >

                                                        {snapshot && (
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <div className="card">
                                                                        <div className="card-body">
                                                                            <div id="snapshotContainer">
                                                                                <img src={snapshot} alt="Snapshot" className="img-fluid" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className="row mt-4">
                                                            <small className={snapshot ? '' : 'd-none'}>Copy the above image</small>
                                                            <div className="col-lg-12">
                                                                <button className={snapshot ? 'd-none' : 'btn btn-primary'} type="button" onClick={sendSnap}> Generate Reciept</button>
                                                                <button className={snapshot ? 'btn btn-primary' : 'd-none'} type="button" onClick={sendWhatsApp}> Send Reciept</button>
                                                            </div>
                                                        </div>

                                                    </Modal>
                                                    <div className="row" style={{ justifyContent: 'center' }}>
                                                        <div className="col-lg-8">
                                                            <div className="card d-none" id="RoomReciept" ref={tableRef}>
                                                                <div className="modal-header" style={{ display: 'flex' }}>
                                                                    <div>
                                                                    <h3 className="cinzel-Font">Welcome to Raihan Rooms</h3>
                                                                    <span> <i className="bi bi-person-circle"></i> {selectedUser.name}    <i className="bi bi-phone-fill"></i> {selectedUser.mobile}</span>
                                                                    </div>
                                                                    {/* {isIstefada ? (<img src={istefada} style={{height:'100px'}}></img>):''} */}

                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="col-lg-6">
                                                                            <p className="d-inline-flex" style={{ alignItems: 'baseline' }}>Arrival : <span style={{ fontWeight: 'bold' }}>{selectedUser.chkIn}</span></p>
                                                                        </div>
                                                                        <div className="col-lg-6">
                                                                            <p className="d-inline-flex" style={{ alignItems: 'baseline' }}>Departure : <span style={{ fontWeight: 'bold' }}>{selectedUser.chkOut}</span></p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mt-3">
                                                                        <div className="col-lg-12" style={{ textAlign: 'center',fontSize:'larger' }}>
                                                                            <span>Room no: {selectedUser.Roomno}</span>
                                                                            <span style={{ marginLeft: '20px' }}>Total Days: {selectedUser.noOfdays}</span>
                                                                            <span style={{ marginLeft: '20px' }}>Total Guests: {selectedUser.noOfguests}</span>
                                                                        </div>

                                                                    </div>
                                                                    <div className="row mt-3">
                                                                        <div className="col-lg-12" style={{ textAlign: 'center' ,fontSize:'larger'}}>
                                                                            <span>Amount Estimate:  {selectedUser.est}</span>
                                                                            {selectedUser.dis !== undefined ? <span style={{ marginLeft: '20px' }}> Discount : {selectedUser.dis}</span> : <></>}

                                                                        </div>
                                                                    </div>
                                                                    <div className="row mt-3">
                                                                        <div className="col-lg-6" style={{ alignContent: 'center' }}>
                                                                            <h2 class="badge badge-danger" style={{ color:'red',fontSize:'larger' }}><span>Booking amount paid : {selectedUser.paid}</span></h2>
                                                                            <h3>
                                                                                <span>Amout to be paid on check-in :{selectedUser.netamt}</span>
                                                                            </h3>
                                                                        </div>
                                                                        <div className="col-lg-6">
                                                                            <img src={qrcode} className="img-fluid"></img>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="modal-footer" style={{ justifyContent: 'center', color: '#d72d2d' }}>
                                                                    <h3>Balance amount to be paid on check-in</h3>
                                                                    <span style={{ color: 'black' }}>
                                                                        Help Desk : +91 9376921552
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <p>No Booking </p>
                                            )
                                    }
                                </div>
                                <div className="tab-pane fade" id="ex1-tabs-2" role="tabpanel" aria-labelledby="ex1-tab-2">
                                    {
                                        CheckIn.length > 0 ?
                                            (
                                                <>
                                                    <div className="row justify-content-center">
                                                        <div className="col-lg-8">
                                                            <table className="table table-hover text-center">
                                                                <thead>
                                                                    <tr>
                                                                        <th>
                                                                            Room No.
                                                                        </th>
                                                                        <th>
                                                                            Name
                                                                        </th>
                                                                        <th>
                                                                            Mobile
                                                                        </th>
                                                                        <th>
                                                                            Check-in
                                                                        </th>
                                                                        <th>
                                                                            Check-out
                                                                        </th>
                                                                        <th>
                                                                            Guest count
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        CheckIn.map((rooms, index) => (
                                                                            <tr key={index}>
                                                                                <td>{rooms.RoomNo}</td>
                                                                                <td>{rooms.ChkInCust}</td>
                                                                                <td>{rooms.ChkInCustMob}</td>
                                                                                <td>{rooms.ChkInDate != "Invalid date" ? rooms.ChkInDate : "--"}</td>
                                                                                <td>{rooms.ChkOutDate != "Invalid date" ? rooms.ChkOutDate : "--"}</td>
                                                                                <td>{rooms.ChkInGuestsCount}</td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <p> No Arrivals Today</p>
                                            )
                                    }
                                </div>
                                <div className="tab-pane fade" id="ex1-tabs-3" role="tabpanel" aria-labelledby="ex1-tab-3">
                                    {
                                        CheckIn.length > 0 ?
                                            (
                                                <>
                                                    <div className="row justify-content-center">
                                                        <div className="col-lg-8">
                                                            <table className="table table-hover text-center">
                                                                <thead>
                                                                    <tr>
                                                                        <th>
                                                                            Room No.
                                                                        </th>
                                                                        <th>
                                                                            Name
                                                                        </th>
                                                                        <th>
                                                                            Mobile
                                                                        </th>
                                                                        <th>
                                                                            Check-in
                                                                        </th>
                                                                        <th>
                                                                            Check-out
                                                                        </th>
                                                                        <th>
                                                                            Guest count
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        CheckIn.map((rooms, index) => (
                                                                            <tr key={index}>
                                                                                <td>{rooms.RoomNo}</td>
                                                                                <td>{rooms.ChkInCust}</td>
                                                                                <td>{rooms.ChkInCustMob}</td>
                                                                                <td>{rooms.ChkInDate != "Invalid date" ? rooms.ChkInDate : "--"}</td>
                                                                                <td>{rooms.ChkOutDate != "Invalid date" ? rooms.ChkOutDate : "--"}</td>
                                                                                <td>{rooms.ChkInGuestsCount}</td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <p>No Departures Today</p>
                                            )
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ApproveBooking;