import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import NavBar from "./navBar";
import '../assests/css/profile.css'
import logo from '../assests/img/RaihanLogo.png'
import { Popconfirm, message } from "antd";
const UserProfile = () => {
    const token = localStorage.getItem('token');
    const config = {
        headers: {
            Authorization: token
        }
    };
    const [userData, setUserData] = useState('');
    const [Email, setEmail] = useState('');
    const [Mobile, setMobile] = useState('');
    const [Address, setAddress] = useState('');

    useEffect(() => {
       
        getProfile();
    }, []);
    async function getProfile() {
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/Api/UserProfile`, config)
                .then(res => {
                    if (res.status === 200) {
                        setUserData(res.data);
                    }
                }).catch(err => console.log(err));
        }
        catch (error) {

        }
    }
    async function handleConfirmUpdate(email, mobile, address) {
        try {
            
            await UpdateData(email, mobile, address);
        } catch (error) {
           
            console.error(error);
        }
    }
    async function UpdateData(email,mobile,address){
        try{
            let emailid,mob,add;
            if(Email == null || Email == ''){
                emailid = email;               
            } else{
                emailid = Email;              
            }
            if(Mobile == null || Mobile == ''){
                mob = mobile;
            } else{
                mob = Mobile;
            }
            if(Address == null || Address == ''){
                add = address
            }else{
                add = Address
            }
            const Data = {emailid,mob,add};
            axios.post(`${process.env.REACT_APP_API_URL}/Api/UpdUserData`,Data,config)
            .then(res =>{
                if(res.status === 200){
                    message.success(res.data.message);
                    getProfile();
                } if(res.status === 300){
                    message.error(res.data.message);
                }
            }).catch(err => console.log(err));
        } catch(error){
            message.error("Something went wrong . Try again later !")
        }
    }
    return (
        <>
            <NavBar />
            <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.6.1/css/all.css" integrity="sha384-gfdkjb5BdAXd+lj+gudLWI+BXq4IuLW5IT+brZEZsLFm++aCMlF1V92rMkPaX4PP" crossOrigin="anonymous" />
            <div className="card" style={{ height: '100vh', background: 'none', border: 'none' }}>
                <div className="card-body">
                    <div className="row smScreen">
                        <div className="col-lg-12" style={{ display: 'flex', justifyContent: 'center' }}>
                            <main className="profile">
                                <div className="profile-bg"></div>
                                <section className="containerrr">
                                    <aside className="profile-image shadow shadow-ms" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src={logo} className="smScreenDisplay"></img>
                                        <a className="camera" href="#">
                                            <i className="fas fa-camera"></i>
                                        </a>
                                    </aside>
                                    <section className="profile-info">
                                        <h1 className="first-name">
                                            {
                                                userData.length > 0 ? (
                                                    <>
                                                        {
                                                            userData.map((Data, index) => (
                                                                <span key={index}>
                                                                    {Data.CustName}
                                                                </span>

                                                            ))
                                                        }
                                                    </>
                                                ) :
                                                    (
                                                        <>

                                                        </>
                                                    )
                                            }
                                        </h1>
                                        <h1 className="second-name"></h1>
                                        <h2>ABOUT</h2>
                                        <p>

                                            {
                                                userData.length > 0 ?
                                                    (
                                                        <>
                                                            {
                                                                userData.map((Data, index) => (
                                                                    <div key={index}>
                                                                        <p>Hello <span style={{ color: '#f63d47' }}>{Data.CustName}</span>, Welcome to Raihan Rooms . Our services include portfolio creation for your business.<a style={{ cursor: 'pointer', color: '#f63d47' }}> Together, let's begin </a>building your customized web portal.</p>
                                                                        <div>
                                                                            <span>Email :</span> <span>{Data.EmailID}</span>
                                                                            <Popconfirm
                                                                                title="Change Email"
                                                                                okText="ok"
                                                                                cancelText="Cancel"
                                                                                onConfirm={() =>handleConfirmUpdate(Data.EmailID,Data.Mobile,Data.Address)}
                                                                                description={
                                                                                    <div>
                                                                                        <div className="col-lg-12">
                                                                                            <input className="form-control" onChange={e => setEmail(e.target.value)} placeholder="Email ID" />
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            >
                                                                                <i className="bi bi-pencil-square" style={{ float: 'right', cursor: 'pointer' }}></i>
                                                                            </Popconfirm>
                                                                        </div>
                                                                        <div>
                                                                            <span>Mobile :</span> <span>{Data.Mobile}</span>
                                                                            <Popconfirm
                                                                                title="Change Mobile"
                                                                                okText="ok"
                                                                                onConfirm={() =>handleConfirmUpdate(Data.EmailID,Data.Mobile,Data.Address)}
                                                                                cancelText="Cancel"
                                                                                description={
                                                                                    <div>
                                                                                        <div className="col-lg-12">
                                                                                            <input className="form-control" type="number" onChange={e => setMobile(e.target.value)} placeholder="Mobile" />
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            >
                                                                                <i className="bi bi-pencil-square" style={{ float: 'right', cursor: 'pointer' }}></i>
                                                                            </Popconfirm>
                                                                        </div>
                                                                        <div>
                                                                            <span>Address :</span> <span>{Data.Address}</span>  
                                                                            <Popconfirm
                                                                                title="Change Address"
                                                                                okText="ok"
                                                                                onConfirm={() =>handleConfirmUpdate(Data.EmailID,Data.Mobile,Data.Address)}
                                                                                cancelText="Cancel"
                                                                                description={
                                                                                    <div>
                                                                                        <div className="col-lg-12">
                                                                                            <input className="form-control" onChange={e => setAddress(e.target.value)} placeholder="Address" />
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            >
                                                                                <i className="bi bi-pencil-square" style={{ float: 'right', cursor: 'pointer' }}></i>
                                                                            </Popconfirm>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </>
                                                    ) :
                                                    (
                                                        <>

                                                        </>
                                                    )
                                            }
                                        </p>


                                        {/* <aside className="social-media-icons">
                                <a href="https://twitter.com/zephybite" target="_blank">
                                    <i className="fab fa-twitter"></i>
                                </a>
                                <a href="https://codepen.io/zephyo" target="_blank">
                                    <i className="fab fa-codepen"></i>
                                </a>
                                <a href="https://github.com/zephyo" target="_blank">
                                    <i className="fab fa-github"></i>
                                </a>
                            
                            <a href="https://medium.com/@zephyo" target="_blank">
                                <i className="fab fa-medium"></i>
                            </a>
                        </aside> */}
                                    </section>
                                </section>
                                <section className="statistics">
                                    <button className="icon arrow left"></button>
                                    <button className="icon arrow right"></button>
                                    <p><strong></strong> </p>
                                    <p><strong></strong> </p>
                                    <p><strong></strong> </p>
                                </section>
                                <button className="icon close"></button>
                            </main>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default UserProfile;