import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '../reducers/index'; // Your root reducer


const store = configureStore({
  reducer: rootReducer,
  // Other middleware can be added here, e.g., middleware, devtools setup, etc.
});

export default store;
