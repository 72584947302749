export const ExtraCharge = (maxOcc,ActualOcc,rateQuote,totalDays,estimate) =>{
    if(maxOcc < ActualOcc){
        const actualRate = rateQuote * totalDays;
        const extras = estimate - actualRate;
        return extras;
    } else{
        return estimate
    }
}
export const RecalculateForCustomPrice = (originalRate,totalDays,AmtEstimate,customPrice) =>{
    let newPrice = customPrice * totalDays;
    const oldPrice = originalRate * totalDays;
    const extras = AmtEstimate - oldPrice;    
    if(extras > 0){      
        const withExtras = newPrice + extras;          
        return withExtras;
    }else{
        return newPrice;
    }

}