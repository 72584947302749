import CryptoJS from 'crypto-js';
const key = process.env.REACT_APP_KEY;

function encryptData(data) {
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
    const encodedString = encodeURIComponent(encrypted);
    return encodedString;
//     const iv = CryptoJS.lib.WordArray.random(16); // Generate a random IV

//   const encrypted = CryptoJS.AES.encrypt(data, key, { iv: iv });
//   const ciphertext = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
//   const ivString = CryptoJS.enc.Base64.stringify(iv);

//   // Make the strings URL-safe
//   const safeCiphertext = encodeURIComponent(ciphertext);
//   const safeIvString = encodeURIComponent(ivString);

//   return safeCiphertext + ':' + safeIvString;
}
function decryptData(encryptedData){
    const decrypted = CryptoJS.AES.decrypt(decodeURIComponent(encryptedData), key).toString(CryptoJS.enc.Utf8);
    const decryptedData = JSON.parse(decrypted);
    return decryptedData;
//     const [safeCiphertext, safeIvString] = encryptedData.split(':');
//   const ciphertext = decodeURIComponent(safeCiphertext);
//   const ivString = decodeURIComponent(safeIvString);

//   const iv = CryptoJS.enc.Base64.parse(ivString);

//   const decrypted = CryptoJS.AES.decrypt({ ciphertext: CryptoJS.enc.Base64.parse(ciphertext) }, key, { iv: iv });
//   const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
//   const decryptedData = JSON.parse(decryptedText);
//   return decryptedData;
}
export {encryptData,decryptData}