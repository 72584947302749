import React, { useState } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import authAction from '../actions/authAction';
import logo from '../assests/img/alm.png'
import { message, Popconfirm } from "antd";


const NewLogin = () => {

    const dispatch = useDispatch();
    const [action, setAction] = useState("Log-in");
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [user, setUser] = useState('');
    const [mobile, setMobile] = useState('');
    const [url, setUrl] = useState('');
    const [confirmLoading, setConfirmLoading] = useState(false);

    const navigate = useNavigate();
    const cursorStyle = {
        cursor: 'pointer',
    };

    async function encryptPasswordOnServer(password) {
        try {
            //setUrl(process.env.REACT_APP_API_URL);
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/Api/encrypt-password`, { password });
            return response.data.encryptedPassword;
        } catch (error) {
            console.error('Encryption failed', error);
            return null;
        }
    }
    async function sendPwd(event) {
        event.preventDefault();

        try {
            setConfirmLoading(true);
            const data = { email };
            axios.post(`${process.env.REACT_APP_API_URL}/Api/forgotPwd`, data)
                .then(res => {
                    setConfirmLoading(false);
                    if (res.status === 200) {
                        message.success(res.data.message);
                    }
                    if (res.status === 201) {
                        message.error(res.data.message);
                    }
                }).catch(err => setConfirmLoading(false))
        } catch (error) {
            setConfirmLoading(false);
        }
    }
    function cancel (){
        setConfirmLoading(false);
    }
    async function handleSubmit(event) {
        event.preventDefault();
        //const encryptedPassword = await encryptPasswordOnServer(password);
        const userData = { email, password, mobile, user, action };
        if (action === "Log-in") {
            if (email.length > 0 && password.length > 0) {
                axios.post(`${process.env.REACT_APP_API_URL}/Api/login`, userData)
                    .then(res => {
                        if (res.status === 200) {
                            const { action: responseAction, UserID: user, Dtoken: token } = res.data;
                            localStorage.setItem('token', token);
                            if (responseAction === "Sign-Up") {
                                setAction("Log-in");
                            } else {

                                dispatch(authAction.loginSuccess(user));
                                navigate("/adminBookingReq");
                            }
                        }
                    })
                    .catch(err => console.log(err));
            } else {
                message.error("Fields are empty");
            }
        } if (action === "Sign-Up") {
            if (user.length > 0 && mobile.length > 0 && email.length > 0 && password.length > 0) {
                axios.post(`${process.env.REACT_APP_API_URL}/Api/login`, userData)
                    .then(res => {
                        if (res.status === 200) {
                            const { action: responseAction, UserID: user, Dtoken: token } = res.data;
                            localStorage.setItem('token', token);
                            if (responseAction === "Sign-Up") {
                                setAction("Log-in");
                            } else {

                                dispatch(authAction.loginSuccess(user));
                                navigate("/adminBookingReq");
                            }
                        }
                    })
                    .catch(err => console.log(err));
            } else {
                message.error("Please fill the req fields")
            }
        }
    }
    return (
        <>
            <style>
                {
                    `
    body {
        background: rgb(231,176,229);
        background: linear-gradient(260deg, rgba(231,176,229,1) 0%, rgba(207,151,204,1) 49%, rgba(201,153,179,1) 82%);
        font-family: 'Montserrat', sans-serif;
    }
    
    .containerr {
        position: fixed;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
    }
    .btn-outline-success:hover {
        color: #fff;
        background-color: #509b78;
        border-color: #86cbaa;
    }
    .btn-outline-success {
        border:none;
        color: #00a136;
        border-color: #eff5f1;
    }
    form {
        /* background: rgba(255, 255, 255, 0.3); */
        padding: 3em;
        /* height: 320px; */
        border-radius: 20px;
        border-left: 1px solid rgba(255, 255, 255, 0.3);
        border-top: 1px solid rgba(255, 255, 255, 0.3);
        backdrop-filter: blur(10px);
        box-shadow: 20px 20px 40px -6px rgba(0, 0, 0, 0.2);
        text-align: center;
        position: relative;
        transition: all 0.2s ease-in-out;
    }
    
    form h4 {
        font-weight: 500;
        color: black;
        opacity: 0.7;
        font-size: 1.4rem;
        margin-top: 0;
        margin-bottom: 60px;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    }
    
    form a {
        text-decoration: none;
        color: #0000008f;
        font-size: 12px;
    }
    
    form a:hover {
        text-shadow: 2px 2px 6px #00000040;
    }
    
    form a:active {
        text-shadow: none;
    }
    
    form input {
        background: transparent;
        width: 200px;
        padding: 1em;
        margin-bottom: 2em;
        border: none;
        border-left: 1px solid rgba(255, 255, 255, 0.3);
        border-top: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 5000px;
        backdrop-filter: blur(5px);
        box-shadow: 4px 4px 60px rgba(0, 0, 0, 0.2);
        color: black;
        font-family: Montserrat, sans-serif;
        font-weight: 500;
        transition: all 0.2s ease-in-out;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    }
    
    form input:hover {
        background: rgba(255, 255, 255, 0.1);
        box-shadow: 4px 4px 60px 8px rgba(0, 0, 0, 0.2);
    }
    
    form input[type="email"]:focus,
    form input[type="password"]:focus {
        background: rgba(255, 255, 255, 0.1);
        box-shadow: 4px 4px 60px 8px rgba(0, 0, 0, 0.2);
    }
    
    form input[type="button"] {
        margin-top: 10px;
        width: 150px;
        font-size: 1rem;
    }
    
    form input[type="button"]:hover {
        cursor: pointer;
    }
    
    form input[type="button"]:active {
        background: rgba(255, 255, 255, 0.2);
    }
    
    form:hover {
        margin: 14px;
    }
    
    ::placeholder {
        font-family: Montserrat, sans-serif;
        font-weight: 400;
        color: #fff;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
    }
    
    .drop {
        background: rgba(255, 255, 255, 0.3);
        backdrop-filter: blur(10px);
        border-radius: 10px;
        border-left: 1px solid rgba(255, 255, 255, 0.3);
        border-top: 1px solid rgba(255, 255, 255, 0.3);
        box-shadow: 10px 10px 60px -8px rgba(0, 0, 0, 0.2);
        position: absolute;
        transition: all 0.2s ease;
    }
    
    .drop-1 {
        height: 80px;
        width: 80px;
        top: -20px;
        left: -40px;
        z-index: -1;
    }
    
    .drop-2 {
        height: 80px;
        width: 80px;
        bottom: -30px;
        right: -10px;
    }
    
    .drop-3 {
        height: 100px;
        width: 100px;
        bottom: 120px;
        right: -50px;
        z-index: -1;
    }
    
    .drop-4 {
        height: 120px;
        width: 120px;
        top: -60px;
        right: -60px;
    }
    
    .drop-5 {
        height: 60px;
        width: 60px;
        bottom: 70px;
        left: -31px;
        z-index: -1;
    }
    
    a,
    input:focus,
    select:focus,
    textarea:focus,
    button:focus {
        outline: none;
    }
    
    `
                }
                {/* background: rgb(231,176,229);
background: linear-gradient(90deg, rgba(231,176,229,1) 0%, rgba(207,151,204,1) 49%, rgba(201,153,179,1) 100%);

background: rgb(217,215,255);
background: linear-gradient(90deg, rgba(217,215,255,1) 0%, rgba(189,130,186,1) 49%, rgba(213,152,185,1) 100%); */}
            </style>
            <div className="">
                <div className="card-body">
                    <div className="containerr">
                        <form onSubmit={handleSubmit}>
                            <h4>Raihan Rooms</h4>
                            {action === "Log-in" ? <div></div> : <div className="row">
                                <div className="col-lg-12">
                                    <input type="text" id="user" placeholder="User name" onChange={e => setUser(e.target.value)} />
                                </div>

                            </div>
                            }
                            {action === "Log-in" ? <div></div> : <div className="row">
                                <div className="col-lg-12">
                                    <input type="text" id="Mobile" placeholder="Mobile" onChange={e => setMobile(e.target.value)} />
                                </div>
                            </div>
                            }
                            <div className="row">
                                <div className="col-lg-12">
                                    <input type="email" placeholder="Email" onChange={e => setEmail(e.target.value)} /><br />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <input type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} /><br />
                                </div>
                            </div>
                            <button type="submit" className="btn btn-outline-success">{action}</button><br />
                            <div className="row">
                                <div className="col-lg-12">
                                    <p className={action === "Log-in" ? "d-block" : "d-none"} onClick={() => { setAction("Sign-Up") }} style={cursorStyle}>Create Account</p>
                                    <p className={action === "Sign-Up" ? "d-block" : "d-none"} onClick={() => { setAction("Log-in") }} style={cursorStyle}>Log-in</p>
                                </div>
                            </div>
                            <Popconfirm
                                title="Forgot password"
                                okText="Send password"
                                cancelText="Cancel"
                                onConfirm={sendPwd}
                                onCancel={cancel}
                                okButtonProps={{
                                    loading: confirmLoading,
                                }}
                                description={
                                    <div>
                                        <div className="col-lg-12">
                                            <input className="form-control" onChange={e => setEmail(e.target.value)} placeholder="Email ID" />
                                        </div>
                                    </div>
                                }
                            >
                                <a href="#">Forgot Password?</a>
                            </Popconfirm>
                        </form>

                        <div className="drops">
                            <div className="drop drop-1"></div>
                            <div className="drop drop-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={logo} alt="R" style={{ height: '30px', width: '30px' }}></img>
                            </div>
                            <div className="drop drop-3"></div>
                            <div className="drop drop-4"></div>
                            <div className="drop drop-5"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

// <Link type="submit" onClick={props.login}>Log-in</Link>
export default NewLogin