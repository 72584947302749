import React, { useState, useEffect } from "react";
import axios from 'axios';
import { DatePicker } from 'antd';
import moment from 'moment';
import 'moment-timezone';
import { Avatar, Card, Button, Modal, message } from 'antd';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import NavBar from "./navBar";
const RoomAvailable = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [UserID, setUserID] = useState(0);
  const token = localStorage.getItem('token');

  const config = {
    headers: {
      Authorization: token
    }
  };
  const navigate = useNavigate();
  // const selectUser = state => state.auth.userID;
  // const user = useSelector(selectUser);
  // useEffect(() => {
  //   const checkLoginStatus = async () => {
  //     const isLogin = await logInStatus();
  //     if (isLogin === 400) {
  //       console.log(isLogin);
  //       navigate("/");
  //     }
  //   };

  //   checkLoginStatus();

  // }, []);

  // async function logInStatus() {
  //   try {
  //     const responce = await axios.get(`${process.env.REACT_APP_API_URL}/Api/checkLogInStatus`, config);
  //     if (responce.status === 200) {
  //       setUserID(responce.data.UserID);
  //       return responce.data.UserID;

  //     }
  //     else if (responce.status === 400) {
  //       setUserID(400);
  //       return 400;
  //     }
  //     return 400;
  //   } catch (error) {
  //     setUserID(400);
  //     console.error('Error:', error); // Handle network errors or exceptions
  //     return 400;
  //   }

  // }


  const [dateRange, setDateRange] = useState([]);
  const [arrival, setArrival] = useState('');
  const [departure, setDeparture] = useState('');
  const [roomData, setRoomData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState('');
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [Occupancy, setOccupancy] = useState(0);

  const showModal = async (roomID) => {
    try {
      const room = roomData.find((room) => room.RoomID === roomID);
      if (room) {
        setSelectedRoom(room);
        setOpen(true);
      } else {
        console.log("Cannot open modal - Room not found");
      }
    } catch (error) {
      console.error('Error:', error); // Handle any potential errors
    }
  };

  const handleOk = async () => {
    try {
      setConfirmLoading(true);
        const Arrival = moment.tz(arrival, 'DD-MM-YYYY HH:mm', 'Asia/Kolkata').toDate();
        const Departure = moment.tz(departure, 'DD-MM-YYYY HH:mm', 'Asia/Kolkata').toDate();
        if (selectedRoom) {

          const roomid = selectedRoom.RoomID;
          const estimate = selectedRoom.EstimatedPrice;
          const noOfDays = selectedRoom.TotalDays;
          const stdRate = selectedRoom.RoomStdRate;
          const hasBooking = selectedRoom.HasBooking;          
          const booking = { roomid, Arrival, Departure, estimate, noOfDays, stdRate, Occupancy };
          if (!hasBooking && selectedRoom.IsAvailable ) {  //&& UserID !== 0 && UserID !== 400
            axios.post(`${process.env.REACT_APP_API_URL}/Api/ReqBooking`, booking, config)
              .then(res => {
                if (res.status === 200) {
                  setConfirmLoading(false);
                  setOpen(false);
                  selectedRoom.HasBooking = true;
                  message.success("Room request recieved");
                }
                else if (res.status === 500) {
                  setConfirmLoading(false);
                  setOpen(false)
                  setStatus("0");
                  message.error("error occured");
                }
              }).catch(err => console.log(err));
          } else {
            console.log("Cannot process your request")            
          }
        }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const handleCancel = () => {
    setOpen(false);
    setSelectedRoom(null);
    setConfirmLoading(false);
    setStatus("0");
  };

  const handleRangeChange = (dates) => {
    if (!dates || dates.length === 0) {
      setDateRange([]);
      setArrival('');
      setDeparture('');
      setRoomData([]);

    } else {
      setDateRange(dates);
      setArrival(dates[0]?.format('DD-MM-YYYY HH:mm'));
      setDeparture(dates[1]?.format('DD-MM-YYYY HH:mm'));

    }
  };
  const disabledDate = (current) => {
    // Disable dates before 24 hours from the current time
    //return current && current < moment().add(24, 'hours').startOf('day');
    return current && current < moment().startOf('day');
  };
  async function checkRoom(event) {
    event.preventDefault();
    const Arrival = moment.tz(arrival, 'DD-MM-YYYY HH:mm', 'Asia/Kolkata').toDate();
    const Departure = moment.tz(departure, 'DD-MM-YYYY HH:mm', 'Asia/Kolkata').toDate();
    setLoading(true);
    const Dates = { Arrival, Departure, arrival, departure, UserID };  
      axios.post(`${process.env.REACT_APP_API_URL}/Api/checkAvailability`, Dates, config)
        .then(res => {
          if (res.status === 200) {
            setRoomData(res.data);
            setLoading(false);
            console.log(roomData);

          }
        }).catch(err => console.log(err));
    
  }
  return (
    <div>
      <NavBar></NavBar>
      <div className="d-flex justify-content-center mt-3">
        <div className="col-lg-4">
          <div className="card">
            <div className="modal-header" style={{ border: 'none', justifyContent: 'center' }}>
              <h3>Tell us when you plan to visit</h3>
            </div>
            <div className="card-body justify-content-center d-flex">
              <div className="row ">
                <div className="col-lg-12">
                  <DatePicker.RangePicker onChange={handleRangeChange}
                    format="DD-MM-YYYY"
                    showTime={{ format: 'HH:mm', minuteStep: 5 }}
                    disabledDate={disabledDate} />

                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-12 text-center" style={{ fontSize: 'small' }}>
                <p className={arrival ? "" : "d-none"} style={{ fontFamily: 'poppins' }}><span style={{ fontWeight: 'bold' }}>CheckIn : </span>{arrival}</p>
                <p className={departure ? "" : "d-none"} style={{ fontFamily: 'poppins' }}><span style={{ fontWeight: 'bold' }}>CheckOut : </span>{departure}</p>
              </div>

            </div>
            <div className="modal-footer">
              <div className="col-lg-5" style={{ display: 'flex', alignItems: 'center' }}>
                <input type="number" className="form-control" placeholder="No of Guests" onChange={e => setOccupancy(e.target.value)} />
              </div>
              <button className="btn btn-success col-lg-2" type="submit" onClick={checkRoom}>Check</button>
            </div>
          </div>

        </div>
      </div>
      <div className="mt-3" style={{ display: 'flex', justifyContent: 'center' }}>
        <div className="col-lg-10 mt-3" style={{ padding: '16px' }}>
          <div className="card shadow shadow-sm">
            <div className="card-body">
              {roomData.length > 0 && dateRange.length > 0 ? (
                <div className="row">


                  {/* <div className="col-lg-3"> */}
                  <h5>Rooms</h5>
                  {
                    roomData.map(room => (
                      <div className="col-lg-3 col-md-6 mb-3" key={room.RoomID}>
                        <Card loading={loading}
                          key={room.RoomID}

                          className="mt-2 shadow shadow-md"
                          cover={
                            <div className="text-center" style={{ marginTop: '10px' }}>
                              <i className="bi bi-house-fill" style={{ fontSize: '30px', justifyContent: 'center', display: 'flex' }}></i>
                              <p style={{ fontSize: 'x-large', fontWeight: 'bold', marginBottom: '5px' }}>{room.RoomNo}</p>
                              <span style={{ fontFamily: 'poppins', fontSize: 'medium' }}>Cost: INR {room.EstimatedPrice} / {room.TotalDays} days</span>
                            </div>
                          }
                          actions={[
                            <div>
                              <button className={room.HasBooking ? "btn btn-danger col-lg-6 " : (room.IsAvailable ? "btn btn-success col-lg-6 " : "btn btn-danger col-lg-6 ")} key="book" type="submit" onClick={() => showModal(room.RoomID)} disabled={room.HasBooking ? true : (room.IsAvailable ? false : true)}> {room.HasBooking ? (room.WhoBooked == UserID ? "Req Approved" : "Already Req") : (room.IsAvailable ? "Req Booking" : "Not Available")}</button>
                              {/* {room.HasBooking? (<button className="btn btn-danger " style={{marginLeft:'20px'}} onClick={() => cancel(room.BookingID)}>Cancel</button>): ("")} */}
                            </div>
                          ]}
                        >
                          <Card.Meta
                            avatar={<Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel" />} // Add room image or icon
                            title={<span style={{ fontFamily: 'poppins' }}><span style={{ color: room.IsAvailable ? 'green' : '#fd7272' }}>{room.IsAvailable ? 'Available' : ''}</span> ~Raihan Rooms</span>}
                            description={`Description: ${room.RoomDesc} | Price: ${room.RoomStdRate}`}
                          />
                        </Card>
                      </div>
                    ))}
                  {/* </div> */}

                </div>
              ) : (
                <p>No room data available</p>

              )}
            </div>
          </div>

        </div>
      </div>
      <Modal
        title={selectedRoom && selectedRoom.RoomNo ? `Room No - ${selectedRoom.RoomNo}` : 'No Room Selected'}
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={
          selectedRoom ? (
            selectedRoom.HasBooking ? (
              <Button key="cancel" onClick={handleCancel}>
                Close
              </Button>
            ) : (
              selectedRoom.IsAvailable ? (
                <Button key="ok" onClick={handleOk}>
                  Request booking
                </Button>
              ) : (
                <Button key="cancel" onClick={handleCancel}>
                  Close
                </Button>
              )
            )
          ) : null // If selectedRoom is null, render null or handle this case accordingly
        }

      >
        {status === "1" ? (
          <p className="text-center mt-2" style={{ color: "green", fontFamily: 'poppins' }}>
            Request Recieved
          </p>
        ) : (
          selectedRoom && (
            <>
              <p><span style={{ fontWeight: '550', fontFamily: 'poppins' }}>Description: </span><span style={{ fontFamily: 'poppins' }}>{selectedRoom.RoomDesc} | {selectedRoom.RoomStdRate} /- <span style={{ fontSize: 'small' }}>per day</span></span></p>
              <span style={{ fontFamily: 'poppins' }}>
                <span style={{ fontWeight: '550' }}>Estimate : </span>
                <span > INR {selectedRoom.EstimatedPrice} / </span>
                <span >{selectedRoom.TotalDays} days</span>
              </span>
              {/* Display other room details as needed */}
            </>
          )
        )}

      </Modal>


    </div>
  )
};
export default RoomAvailable;