import moment from 'moment';


export const convertToDate = (dateString, format) => {
  return moment(dateString, format).toDate();
};


export const calculateNumberOfDays = (arrivalString, departureString, format) => {
  const oneDay = 24 * 60 * 60 * 1000;


  const arrivalDate = convertToDate(arrivalString, format);
  const departureDate = convertToDate(departureString, format);


  const diffInMilliseconds = departureDate.getTime() - arrivalDate.getTime();


  const millisecondsPerHour = 60 * 60 * 1000;
  const totalHours = diffInMilliseconds / millisecondsPerHour;
  const modVal = totalHours % 24;
  const numberOfDays = Math.round(Math.abs(diffInMilliseconds / oneDay));

  let netDays = numberOfDays;
  if (numberOfDays === 0) {
    netDays = 1;
  } else if (modVal > 1) {
    netDays = Math.floor(totalHours / 24) + 1;
  }

  return netDays;
};