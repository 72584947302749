import { useState, useEffect } from "react"
import axios from "axios"
import { DatePicker, Popover } from 'antd';
import moment from 'moment';
import 'moment-timezone';
import NavBar from "./navBar";
import { Avatar, Card, Button, Modal, message } from 'antd';
import { RecalculateForCustomPrice } from "../Reusable/extraCharge";
const AdminBookingReq = () => {
    const token = localStorage.getItem('token');
    const config = {
        headers: {
            Authorization: token
        }
    };
    const [dateRange, setDateRange] = useState([]);
    const [arrival, setArrival] = useState('');
    const [departure, setDeparture] = useState('');
    const [roomData, setRoomData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedRoom, setSelectedRoom] = useState(null);
    const [open, setOpen] = useState(false);
    const [pkg, setPackage] = useState([]);
    const [selectPkg, setSelectPkg] = useState([]);
    const [confirmLoading, setConfirmLoading] = useState(false);


    const [password, setPassword] = useState('');
    const [mobile, setMobile] = useState('');
    const [userData, setUserData] = useState([]);
    const [nameInput, setnameInput] = useState('');
    const [emailInput, setemailInput] = useState('');
    const [CustID, setCustID] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [Occupancy, setOccupancy] = useState(0);
    const [AmtEstimate, setAmt] = useState(0);
    const[stdEstimate,setStdEstimate] = useState(0);
    const [customAmount,setAmount] = useState(0);
    const handlePackageSelect = async (packageID) => {
        const selectedPakage = pkg.find((PKG) => PKG.PackageID === packageID)
        setSelectPkg(selectedPakage);
        try {
            const roomid = selectedRoom.RoomID;
            const totalDays = selectedRoom.TotalDays
            const data = { roomid, totalDays, packageID, Occupancy }
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/Api/getAmtEstimate`, data, config);
            if (res.status === 200) {
                setAmt(res.data.Amt);
                setStdEstimate(res.data.Amt);
            } else {
                setAmt(0);
                setStdEstimate(0);
            }
        } catch (error) {
            message.error('Failed to apply package');
        }

    };
    const showModal = async (roomID) => {
        try {
            const room = roomData.find((room) => room.RoomID === roomID);
            const data = { roomID };

            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/Api/getPackageList`, data, config);

                if (response.status === 200) {
                    setPackage(response.data);
                    setSelectedRoom(room);
                    setOpen(true);
                } else {
                    message.error('Package not found');
                    setPackage([]);
                }

            } catch (error) {
                console.error('Error:', error);
                setPackage([]);
                message.error('Failed to fetch package information');
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };
    const handleCancel = () => {
        setOpen(false);
        setSelectedRoom(null);
        setConfirmLoading(false);
        setMobile('');
        setUserData([]);
        setPackage([]);
        setSelectPkg([]);
        setAmt(0);
    };
    const handleOk = () => {
        const Arrival = moment.tz(arrival, 'DD-MM-YYYY HH:mm', 'Asia/Kolkata').toDate();
        const Departure = moment.tz(departure, 'DD-MM-YYYY HH:mm', 'Asia/Kolkata').toDate();
        const roomid = selectedRoom.RoomID;
        //const estimate = selectedRoom.EstimatedPrice;
        let rateQuote ;
        if(customAmount > 0 ){
            rateQuote = customAmount
        }else{
            rateQuote = selectPkg.PackageRoomRate;
        }
        const noOfDays = selectedRoom.TotalDays;
        const stdRate = selectedRoom.RoomStdRate;
        const PkgID = selectPkg.PackageID;

        const Data = { nameInput, emailInput, mobile, password, Arrival, Departure, roomid, AmtEstimate, noOfDays, stdRate, rateQuote, CustID, discount, Occupancy, PkgID };
        if (nameInput.length > 0 && emailInput.length > 0  && AmtEstimate > 0) {
            if (selectPkg.PackageID !== -1) {
                axios.post(`${process.env.REACT_APP_API_URL}/Api/addAdminBooking`, Data, config)
                    .then(res => {
                        if (res.status === 200) {
                            message.success("Booking done");
                            setOpen(false);
                            setSelectedRoom(null);
                            setUserData([]);
                            selectedRoom.HasBooking = true;
                            setMobile('');
                            setPackage([]);
                            setSelectPkg([]);
                            setAmt(0);
                        } if (res.status === 300) {
                            message.error("Fields are empty");
                        }
                        else {
                            message.error("Booking unsuccessfull");
                            setSelectedRoom(null);
                        }

                    }).catch(err => console.log(err))
            } else {
                message.error("Please select a package");
            }
        } else {
            message.error("Please fill all the feilds");
        }

    };

    const handleRangeChange = (dates) => {
        if (!dates || dates.length === 0) {
            setDateRange([]);
            setArrival('');
            setDeparture('');
            setRoomData([]);

        } else {
            setDateRange(dates);
            setArrival(dates[0]?.format('DD-MM-YYYY HH:mm'));
            setDeparture(dates[1]?.format('DD-MM-YYYY HH:mm'));

        }
    };
    const disabledDate = (current) => {
        return current && current < moment().startOf('day');
    };
    async function checkUser() {
        const Data = { mobile }
        axios.post(`${process.env.REACT_APP_API_URL}/Api/chkUser`, Data, config)
            .then(res => {
                
                if (res.status === 200) {
                    setUserData(res.data);
                    setCustID(res.data.CustID);
                    message.success("User Found");
                } if (res.status === 404) {
                    setUserData([]);
                    message.error("This is a new user");
                    setCustID(0);
                }
                if (res.status === 210) {
                    message.error(res.data.message);
                }
            }).catch(err => {
                console.log(err);
                setUserData([]);
                setCustID(0);
            });
    }
    useEffect(() => {
        if (userData.CustName) {
            setnameInput(userData.CustName);
        } else {
            setnameInput('');
        }

        if (userData.EmailID) {
            setemailInput(userData.EmailID);
        } else {
            setemailInput('');
        }
    }, [userData]);
    async function checkRoom(event) {
        event.preventDefault();
        const Arrival = moment.tz(arrival, 'DD-MM-YYYY HH:mm', 'Asia/Kolkata').toDate();
        const Departure = moment.tz(departure, 'DD-MM-YYYY HH:mm', 'Asia/Kolkata').toDate();
        setLoading(true);
        const Dates = { Arrival, Departure, arrival, departure };

        axios.post(`${process.env.REACT_APP_API_URL}/Api/GetRoomAvailibilityAdmin`, Dates, config)
            .then(res => {
                if(res.status === 400){
                    message.error(res.data.message)
                }
                if (res.status === 200) {
                    const formattedList = res.data.map(item =>{
                        return{
                            ...item,
                            CheckIn : moment(item.CheckIn).format('DD-MM-YYYY HH:mm'),
                            CheckOut : moment(item.CheckOut).format('DD-MM-YYYY HH:mm'),
                        }
                        
                    });
                    setRoomData(formattedList);
                    setLoading(false);
                    //console.log(roomData);
                }
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
            });
    }
    function customAmt(Amt){     
        setAmount(Amt);
        const originalRate = selectPkg.PackageRoomRate;
        const totalDays = selectedRoom.TotalDays;         
        const data = RecalculateForCustomPrice(originalRate,totalDays,stdEstimate,Amt);        
        setAmt(data);       
    }
    return (
        <div>
            <NavBar />
            <div className="d-flex justify-content-center mt-3">
                <div className="col-lg-4">
                    <div className="card">
                        <div className="modal-header" style={{ border: 'none', justifyContent: 'center' }}>
                            <h3>Book a room</h3>
                        </div>
                        <div className="card-body justify-content-center d-flex">
                            <div className="row ">
                                <div className="col-lg-12">
                                    <DatePicker.RangePicker onChange={handleRangeChange}
                                        format="DD-MM-YYYY"
                                        showTime={{ format: 'HH:mm', minuteStep: 5 }}
                                        disabledDate={disabledDate} />

                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-12 text-center" style={{ fontSize: 'small' }}>
                                <p className={arrival ? "" : "d-none"} style={{ fontFamily: 'poppins' }}><span style={{ fontWeight: 'bold' }}>CheckIn : </span>{arrival}</p>
                                <p className={departure ? "" : "d-none"} style={{ fontFamily: 'poppins' }}><span style={{ fontWeight: 'bold' }}>CheckOut : </span>{departure}</p>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <div className="col-lg-5" style={{ display: 'flex', alignItems: 'center' }}>
                                <input type="number" className="form-control" placeholder="No of Guests" onChange={e => setOccupancy(e.target.value)} />
                            </div>
                            <button className="btn btn-success col-lg-2" type="submit" onClick={checkRoom}>Check</button>
                        </div>
                    </div>

                </div>
            </div>
            <div className="mt-3" style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="col-lg-8 mt-3" style={{ padding: '16px' }}>
                    <div className="card shadow shadow-sm">
                        <div className="card-body">
                            {roomData.length > 0 && dateRange.length > 0 ? (
                                <div className="row">


                                    {/* <div className="col-lg-3"> */}
                                    <h5>Rooms</h5>
                                    {
                                        roomData.map(room => (
                                            <div className="col-lg-4 col-md-6 mb-3" key={room.RoomID}>
                                                <Card loading={loading}
                                                    key={room.RoomID}

                                                    className="mt-2 shadow shadow-md"
                                                    cover={
                                                        <div className="text-center" style={{ marginTop: '10px' }}>
                                                            <i className="bi bi-house-fill" style={{ fontSize: '30px', justifyContent: 'center', display: 'flex' }}></i>
                                                            <p style={{ fontSize: 'x-large', fontWeight: 'bold', marginBottom: '5px' }}>{room.RoomNo}</p>
                                                            <span style={{ fontFamily: 'poppins', fontSize: 'medium' }}>Cost: INR {room.EstimatedPrice} / {room.TotalDays} days</span>
                                                        </div>
                                                    }
                                                    actions={[
                                                        <div>
                                                            {
                                                                room.IsAvailable ?
                                                                    <>
                                                                        <button className="btn btn-success col-lg-6 " key="book" type="submit" onClick={() => showModal(room.RoomID)}>Book Now</button>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <Popover
                                                                            title="Room is booked for Dates :"
                                                                            content={room.IsAvailable ? ""
                                                                                :
                                                                                <>
                                                                                    <p><span style={{fontWeight:'bold'}}>CheckIn :</span>{room.CheckIn}</p>
                                                                                    <p><span style={{fontWeight:'bold'}}>CheckOut :</span>{room.CheckOut}</p>
                                                                                </>
                                                                            }
                                                                        >
                                                                            <button type="button" className="btn btn-danger col-lg-7">Check Availibility</button>
                                                                        </Popover>
                                                                    </>
                                                            }
                                                        </div>
                                                    ]}
                                                >
                                                    <Card.Meta
                                                        avatar={<Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel" />} // Add room image or icon
                                                        title={<span style={{ fontFamily: 'poppins' }}><span style={{ color: room.IsAvailable ? 'green' : '#fd7272' }}>{room.IsAvailable ? 'Available' : ''}</span> ~Raihan Rooms</span>}
                                                        description={`Description: ${room.RoomDesc} | Price: ${room.RoomStdRate}`}
                                                    />
                                                </Card>
                                            </div>
                                        ))}

                                </div>
                            ) : (
                                <p>No room data available</p>

                            )}
                        </div>
                    </div>

                </div>
                <Modal
                    title={selectedRoom && selectedRoom.RoomNo ? `Room No - ${selectedRoom.RoomNo}` : 'No Room Selected'}
                    open={open}
                    onOk={handleOk}
                    confirmLoading={confirmLoading}
                    onCancel={handleCancel}


                >
                    {selectedRoom && (
                        <>
                            <p><span style={{ fontWeight: '550', fontFamily: 'poppins' }}>Description: </span><span style={{ fontFamily: 'poppins' }}>{selectedRoom.RoomDesc} </span></p>

                            {/* Display other room details as needed */}
                            <div className="row justify-content-center mt-3">
                                <div className="col-lg-9 text-center">
                                    <div className="">
                                        <h5>Guest details</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-lg-5 col-md-6">
                                    <div className="form-floating">
                                        <input type="number" className="form-control" value={mobile} placeholder="Mobile" onChange={e => setMobile(e.target.value)} onBlur={checkUser} />
                                        <label>Mobile</label>
                                    </div>
                                </div>
                                <div className="col-lg-7 col-md-6">
                                    <div className="form-floating">
                                        <input type="text" className="form-control" value={nameInput} placeholder="Name" onChange={e => setnameInput(e.target.value)}></input>
                                        <label>Name</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-lg-9">
                                    <div className="form-floating">
                                        <input type="text" className="form-control" value={emailInput} placeholder="Email" onChange={e => setemailInput(e.target.value)}></input>
                                        <label>Email</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-lg-12">
                                    <div className="form-floating">
                                        <input type="text" className="form-control" placeholder="Admin remarks"></input>
                                        <label>Admin remarks</label>
                                    </div>
                                </div>
                            </div>
                            <hr></hr>
                            <span style={{ fontFamily: 'poppins' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className="dropdown">
                                        <button className="btn btn-secondary dropdown-toggle" disabled={Occupancy > 0 ? false : true} type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            {pkg.find((item) => item.PackageID === selectPkg.PackageID)?.PackageDesc || 'Select Package'}
                                        </button>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            {pkg.map((packageItem) => (
                                                <a
                                                    key={packageItem.PackageID}
                                                    className={`dropdown-item ${selectPkg === packageItem.PackageID ? 'active' : ''}`}
                                                    onClick={() => handlePackageSelect(packageItem.PackageID)}
                                                    href="#"
                                                >
                                                    {packageItem.PackageDesc}
                                                </a>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-lg-4 col-md-4">
                                        <div className="form-floating">
                                            <input className="form-control" placeholder="Amount"  onBlur={(e) => customAmt(e.target.value)}/>
                                            <label>Amount</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6" style={{alignItems:'center',display:'flex'}}>
                                    <div style={{ width: '100%', textAlign: 'center' }} >
                                    <span style={{ fontWeight: '550' }}>Estimate : </span>
                                    <span >{selectedRoom.TotalDays} days @ </span>
                                    <span > INR {selectPkg.PackageRoomRate}</span>
                                </div>
                                    </div>
                                </div>
                                <br></br>
                                <div className="row">
                                    <div className="col-lg-4 col-md-4">
                                        <div className="form-floating">
                                            <input className="form-control" placeholder="Discount" onChange={e => setDiscount(e.target.value)} />
                                            <label>Discount</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-4" style={{ display: 'flex', alignContent: 'center', flexWrap: 'wrap' }}>
                                        <span style={{ fontWeight: '550' }}>Net Amt :</span><span> INR {AmtEstimate}</span>
                                    </div>
                                </div>
                            </span>
                        </>
                    )}

                </Modal>
            </div>

        </div>
    )

}
export default AdminBookingReq