import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Avatar, Card, Button, Modal, Badge, Popconfirm, message } from 'antd';
import { useSelector } from "react-redux";
import moment from "moment";
import NavBar from "./navBar";
const MyBooking = () => {
    const token = localStorage.getItem('token');
    const config = {
        headers: {
            Authorization: token
        }
    };
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [UserID, setUserID] = useState(0);
    const navigate = useNavigate();
    const userID = useSelector(state => state.auth.userID);
    useEffect(() => {
        getMyBooking();
    }, []);
    const cancel = (e) => {
        console.log(e);
        message.error('Cancelled');
    };
    const confirm = (ID) => {
       axios.post(`${process.env.REACT_APP_API_URL}/Api/CancelBooking`,{ID:ID},config)
       .then(res =>{
        if(res.status === 200){
            message.success('Booking cancelled !');
            getMyBooking();
        } else{
            message.error('Booking was not cancelled');
        }
        
       }).catch(err =>console.log(err));
        
    };
    
    const getMyBooking = async () => {
        try {
            setLoading(true);
            axios.get(`${process.env.REACT_APP_API_URL}/Api/getMyList`, config)
                .then(res => {
                    if (res.status === 200) {
                        setLoading(false);
                        const priorityOrder = ['ACCEPTED', 'REQUESTED', 'CANCELLED'];                        
                        const sortByPriority = (a, b) => {
                          return priorityOrder.indexOf(a.BookingStatus) - priorityOrder.indexOf(b.BookingStatus);
                        };
                        const formattedList = res.data.map(item => {
                            return {
                              ...item,
                              BookingDate: moment(item.BookingDate).format('DD-MM-YYYY HH:mm'),
                              ChkInDate: moment(item.ChkInDate).format('DD-MM-YYYY HH:mm'),
                              ChkOutDate: moment(item.ChkOutDate).format('DD-MM-YYYY HH:mm'),
                             
                            };
                          }).sort(sortByPriority);
                        setList(formattedList);
                        console.log(list);
                    } else {
                        setLoading(false);
                    }
                }).catch(err => console.log(err));
        }
        catch (error) {
            console.log(error);
        }
    }
    // style={{overflowX:'auto'}}
    return (
        <div>
            <NavBar></NavBar>
            <div className="mt-3" style={{justifyContent:'center',display:'flex'}}>
                <div className="col-lg-10" style={{padding:'16px'}}>
                    <h3>My Bookings</h3>
                    <div className="card">
                        <div className="card-body">
                        {
                            list.length > 0 ?
                                (
                                    <div className="row justify-content-center" >
                                        <div className="col-lg-12" >
                                            <table className="table table-bordered text-center table-hover" style={{ fontFamily: 'poppins' }}>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            Booking ID
                                                        </th>
                                                        <th>
                                                            Room type
                                                        </th>
                                                        <th>
                                                            Check-In
                                                        </th>
                                                        <th>
                                                            Check-Out
                                                        </th>
                                                        <th>
                                                            Stay
                                                        </th>
                                                        <th>
                                                            Estimate <span style={{ fontSize: 'small' }}>(INR)</span>
                                                        </th>
                                                        <th>
                                                            Net Amount <span style={{ fontSize: 'small' }}>(INR)</span>
                                                        </th>
                                                        <th>
                                                            Status
                                                        </th>
                                                        <th>

                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        list.map((booking, index) => (
                                                            <tr key={index}>
                                                                <td>{booking.BookigID}</td>
                                                                <td>{booking.RoomDesc}</td>
                                                                <td>{booking.ChkInDate}</td>
                                                                <td>{booking.ChkOutDate}</td>
                                                                <td>{booking.NoOfDays} days</td>
                                                                <td>{booking.AmtEstimate}</td>
                                                                <td>{booking.NetAmt}</td>
                                                                <td>{booking.BookingStatus === "REJECTED"? "UnAvailable" : (booking.BookingStatus === "ACCEPTED" ? "Accepted": (booking.BookingStatus === "REQUESTED"? "Requested" : "Cancelled"))}

                                                                </td>
                                                                <td>
                                                                    <span>
                                                                        <Popconfirm
                                                                            title="Cancel this booking request !"
                                                                            okText="Yes"
                                                                            cancelText="No"
                                                                            onConfirm={() => confirm(booking.BookigID)}
                                                                            onCancel={cancel}
                                                                        >
                                                                            <button className="btn btn-outline-danger" disabled={booking.BookingStatus === "REJECTED" ? true:(booking.BookingStatus === "ACCEPTED"? false :(booking.BookingStatus==="REQUESTED"? false : true))}>Cancel</button>
                                                                            </Popconfirm>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                ) : (
                                    <p>No bookings</p>
                                )
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default MyBooking;