import NavBar from "./navBar";
import React, { useState, useEffect } from 'react';
import { DatePicker, message } from 'antd';
import { disabledDate } from "../Reusable/Date";
import { ExtraCharge } from "../Reusable/extraCharge";
import moment from "moment-timezone";
import axios from "axios";
const BookingReports = () => {
    const token = localStorage.getItem('token');
    const config = {
        headers: {
            Authorization: token
        }

    };
    const [dateRange, setDateRange] = useState([]);
    const [arrival, setArrival] = useState('');
    const [departure, setDeparture] = useState('');
    const [room, setRoom] = useState(0);
    const [Bkstatus, setStatus] = useState('');
    const [filterStr, setStr] = useState('');
    const [report, setReport] = useState([]);
    const [display, setDisplay] = useState('Select Room');

    // useEffect(() => {
    //     document.getElementById('#bookingReport').DataTable();
    //   }, []);   
    const handleRangeChange = (dates) => {
        if (!dates || dates.length === 0) {
            setDateRange([]);
            setArrival('');
            setDeparture('');

        } else {
            setDateRange(dates);
            setArrival(dates[0]?.format('DD-MM-YYYY HH:mm'));
            setDeparture(dates[1]?.format('DD-MM-YYYY HH:mm'));

        }
    };
    async function getReport(event) {
        try {
            event.preventDefault();
            const Arrival = moment.tz(arrival, 'DD-MM-YYYY HH:mm', 'Asia/Kolkata').toDate();
            const Departure = moment.tz(departure, 'DD-MM-YYYY HH:mm', 'Asia/Kolkata').toDate();
            const data = { Arrival, Departure, room, Bkstatus, filterStr };
            await axios.post(`${process.env.REACT_APP_API_URL}/Api/getBookingRep`, data, config)
                .then(res => {
                    if (res.status === 200) {
                        console.log(res.data);
                        const formattedList = res.data.map(item => {
                            return {
                                ...item,
                                BookingDate: moment(item.BookingDate).format('DD-MM-YYYY HH:mm'),
                                ChkInDate: moment(item.ChkInDate).format('DD-MM-YYYY HH:mm'),
                                ChkOutDate: moment(item.ChkOutDate).format('DD-MM-YYYY HH:mm'),
                                // Add other date fields here if necessary
                            };
                        });
                        setReport(formattedList);
                    } if (res.status === 201) {
                        message.error(res.data.message);
                    }

                }).catch(err => console.log(err));

        }
        catch (error) {
            console.log(error);
        }
    }
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredList = report.filter(booking => {
        return (
            booking.CustName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            booking.EmailID.toLowerCase().includes(searchQuery.toLowerCase()) ||
            booking.Mobile.includes(searchQuery) ||
            booking.ChkInDate.includes(searchQuery) ||
            booking.ChkOutDate.includes(searchQuery) ||
            booking.RoomNo.includes(searchQuery)||
            booking.BookingStatus.toLowerCase().includes(searchQuery.toLowerCase())  
        );
    });
    return (
        <>
            <NavBar />
            <div>

                <div className="d-flex mt-3" style={{ justifyContent: 'center' }}>
                    <div className="col-lg-10">
                        <div className="card">
                            <div className="modal-header">
                                <h3>Booking Reports</h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-4 col-md-4 mt-2" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <DatePicker.RangePicker onChange={handleRangeChange}
                                            format="DD-MM-YYYY"

                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-4 mt-2">
                                        <div className="form-floating">
                                            <input type="text" className="form-control" placeholder="name or email or mobile" onChange={e => setStr(e.target.value)} />
                                            <label>name or email or mobile</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-2 mt-2" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <div className="dropdown">
                                            <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                {display}
                                            </button>
                                            <ul className="dropdown-menu" >
                                                <li><a className="dropdown-item" href="#" onClick={() => { setRoom(1); setDisplay("Room No 303"); }}>Room No 303</a></li>
                                                <li><a className="dropdown-item" href="#" onClick={() => { setRoom(2); setDisplay("Room No 304"); }}>Room No 304</a></li>
                                                <li><a className="dropdown-item" href="#" onClick={() => { setRoom(3); setDisplay("Room No 501"); }}>Room No 501</a></li>
                                                <li><a className="dropdown-item" href="#" onClick={() => { setRoom(4); setDisplay("Studio Room"); }}>Studio Room</a></li>
                                                <li><a className="dropdown-item" href="#" onClick={() => { setRoom(0); setDisplay("All Rooms"); }}>All rooms</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-2 mt-2" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <button className="btn btn-primary" onClick={getReport}>Search</button>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-lg-12">
                                        <div style={{ display: 'inline-flex' }}>
                                            <label>
                                                Booking status :
                                            </label>
                                            <div className="form-check">
                                                <input className="form-check-input " type="radio" name="flexRadioDefault" id="flexRadioDefault1" onClick={() => setStatus('ACCEPTED')} />
                                                <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                    ACCEPTED
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" onClick={() => setStatus('REQUESTED')} />
                                                <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                    REQUESTED
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" onClick={() => setStatus('REJECTED')} />
                                                <label className="form-check-label" htmlFor="flexRadioDefault3">
                                                    REJECTED
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="d-flex mt-3" style={{ justifyContent: 'center' }}>
                    <div className="col-lg-10">
                        <div className="card">
                            <div className="card-body">
                                <div className="row justify-content-center">
                                    <div className="col-lg-7">
                                        <div className="form-floating">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="Search..."
                                                value={searchQuery}
                                                onChange={handleSearch}
                                            />
                                            <label>Search...</label>
                                        </div>
                                    </div>
                                </div>
                                <table className="table table-hover mt-3" id="bookingReport">
                                    <thead>
                                        <tr>
                                            <th>Booking ID</th>
                                            <th>Check-In Date</th>
                                            <th>Check-Out Date</th>
                                            <th>Name</th>
                                            <th>Room No.</th>
                                            <th>Package</th>
                                            <th>Rate</th>
                                            <th>Total guests</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredList.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.BookigID}</td>
                                                <td>{item.ChkInDate}</td>
                                                <td>{item.ChkOutDate}</td>
                                                <td>
                                                    <div>
                                                        {item.CustName}
                                                    </div>
                                                    <div>
                                                        <small style={{ fontSize: 'smaller' }}>
                                                            Contact : {item.Mobile}
                                                        </small>
                                                    </div>
                                                </td>
                                                <td>{item.RoomNo}</td>
                                                <td>{item.PkgDesc}</td>
                                                <td>
                                                    <div>
                                                        {item.NetAmt}
                                                    </div>
                                                    <div>
                                                        <small>
                                                            Discount : {item.Discount}
                                                        </small>
                                                    </div>
                                                    <div>
                                                        <small style={{ fontSize: 'smaller' }}>
                                                            Per day : {item.RateQuoted} for {item.NoOfDays} days
                                                            {/* Extras:   {ExtraCharge(item.MaxOccupancy, item.NoOfDays, item.RateQuoted, item.NoOfDays, item.AmtEstimate)} */}
                                                        </small>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div style={{ color: item.MaxOccupancy < item.NoOfGuests ? 'red' : 'green' }}>
                                                        {item.NoOfGuests}
                                                        <small style={{ fontSize: 'smaller' }} className="ml-1"> {item.MaxOccupancy < item.NoOfGuests ? '(OverLoad)' : ''}</small>
                                                    </div>
                                                    <div>
                                                        <small style={{ fontSize: 'smaller' }}>Max Occupancy({item.MaxOccupancy})</small>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default BookingReports